 ::-moz-selection { /* Code for Firefox */
  background: #303030;
}

::selection {
  background: #303030;
}

html, body {
    display: flex;
    justify-content: center;
    align-items: center;
    @apply bg-gray-900 w-full h-full
}

.name,.thing-title {
    text-decoration: underline 1px #616161;
    @apply transition-all
}

.name:hover {
    text-decoration: underline 1px #a6a6a6;
    color: var(--hcolor);
}

.thing-title:hover {
    text-decoration: underline 1px #a6a6a6;
}

.thing {
    @apply flex justify-between align-middle mb-2
}

.thing-wrap {
    animation-delay: calc(var(--anim-order)*100ms + 500ms);
    animation-name: fadeup;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeup {
    from {
        opacity: 0;
        transform: translateY(1rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    25%, 75% {
        opacity: 0;
    }
}

